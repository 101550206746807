<template>
  <v-container
    id="subscribe"
    tag="section"
  >
    <div>
      <v-row>
        <v-col>
          <v-alert
            type="success"
            class="mint_alert"
            icon="mdi-thumb-up"
            v-if="!failed"
          >
            Your payment was successful and your <span v-if="insurance_type">{{ insurance_type }}</span> policy has been renewed!
          </v-alert>
          <v-alert
            type="error"
            class="mint_alert"
            icon="mdi-thumb-down"
            v-if="failed"
          >
            Sorry, your payment was unsuccessful. An error occoured.
          </v-alert>
        </v-col>
      </v-row>
      <v-row
       v-if="!failed">
        <v-col
          cols="12"
        >
          <div 
          class="content_block">
            <p class="description">
              Thank you for continuing to choose us for your <span v-if="insurance_type">{{ insurance_type }}</span> policy.
            </p>
            <p class="description">
              You’ve chosen to pay annually by debit/credit card, and we acknowledge your payment of <span v-if="AmountDue">&pound;{{ AmountDue.toFixed(2) }}</span> made today <span v-if="RenewalDate">{{moment().format('DD/MM/YYYY')}}</span>.
            </p>
            <p class="description">
              We’ll also email you to confirm your payment has been made.
            </p>
          </div>
          <div 
          class="content_block bottom_block">
            <h3 class="payment" align="center">Any questions?</h3>
            <p class="description">
                Full information on your policy can be found in your 
                <a v-if="insurance_type === 'Home Insurance'" href="https://www.paymentshield.co.uk/your-policy-documents/home-insurance">Policy Booklet</a>
                <a v-if="insurance_type === 'Landlords Insurance'" href="https://www.paymentshield.co.uk/your-policy-documents/landlords-insurance">Policy Booklet</a>
                <a v-if="insurance_type === 'Rent Protection'" href="https://www.paymentshield.co.uk/your-policy-documents/rent-protection">Policy Booklet</a>
                <a v-if="insurance_type === 'Tenants Contents'" href="https://www.paymentshield.co.uk/your-policy-documents/tenants-contents">Policy Booklet</a>
                <a v-if="insurance_type === 'Tenants Liability'" href="https://www.paymentshield.co.uk/your-policy-documents/tenants-liability">Policy Booklet</a>
                <a v-if="insurance_type === 'MPPI'" href="https://www.paymentshield.co.uk/your-policy-documents/mortgage-protection">Policy Booklet</a>
                <a v-if="insurance_type === 'STIP'" href="https://www.paymentshield.co.uk/your-policy-documents/income-protection">Policy Booklet</a>, and our <a href="https://www.paymentshield.co.uk/toba">terms of business</a> are available from our website.
            </p>
            <h3 class="payment" align="center">Next steps?</h3>
            <p class="description">
                If you need to make a claim or cancel your policy, simply follow the procedure on your Insurer Schedule or contact our Customer Services team. We recommend you keep all information relating to your policy in a safe place to make sure you have all the relevant details if you need to contact us.
            </p>
            <v-row>
              <v-col
                  align="center"
                  xs="12"
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                  offset-xs="0"
                  offset-sm="0"
                  offset-md="4"
                  offset-lg="4"
                  offset-xl="4"
              >
                <a :href="'https://www.paymentshield.co.uk/'"
                class="no_underline">
                    <v-btn
                    x-large
                    color="success"
                    class="mintBtn"
                    width="100%">
                        Return to paymentshield
                    </v-btn>
                </a>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeSubscribe',
    data() {
      return {
        insurance_type: sessionStorage.getItem('insurance_type'),
        AmountDue: JSON.parse(sessionStorage.getItem('AmountDue')),
        RenewalDate: sessionStorage.getItem('RenewalDate')
      };
    },
    methods: {
      getCurrentDate: function () {
        var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'/');
      }
    }
  }
</script>
